import React, { useState, useEffect } from "react";
import axios from "axios"; // Assuming use of axios for HTTP requests
import QuicksightEmbedding from "amazon-quicksight-embedding-sdk";

const Dashboard1 = ({ selectedDashboard }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isEmbedded, setIsEmbedded] = useState(false);

  useEffect(() => {
    const fetchAndEmbedDashboard = async () => {
      setLoading(true);
      console.log(`called when ${selectedDashboard}`);

      try {
        const response = await axios.get(
          // "https://droevip52j.execute-api.us-east-1.amazonaws.com/dev"
          "https://f3xhg4bheh.execute-api.us-east-1.amazonaws.com/dev"
        );

        // Log the response for debugging
        console.log("API response:", response.url);

        // Assuming the response data contains an object with the URL in a property named 'url'
        if (response.data && response.data.url) {
          const embeddedURL = response.data.url;
          await embedDashboard(embeddedURL);
        } else {
          throw new Error("Embed URL is undefined");
        }
      } catch (error) {
        console.error("Error fetching and embedding dashboard:", error);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    if (!isEmbedded) {
      fetchAndEmbedDashboard();
    }
  }, [selectedDashboard, isEmbedded]);

  const embedDashboard = async (embeddedURL) => {
    const containerDiv = document.getElementById("dashboardContainer");
    if (!containerDiv) {
      console.error('Element with ID "dashboardContainer" not found');
      return;
    }

    const frameOptions = {
      url: embeddedURL,
      container: containerDiv,
      height: "850px",
      width: "100%",
      resizeHeightOnSizeChangedEvent: true,
    };

    const embeddingContext = await QuicksightEmbedding.createEmbeddingContext();
    embeddingContext.embedDashboard(frameOptions);
    setIsEmbedded(true);
  };
  return (
    <div>
      {loading && <p className="custom-body-large">Loading dashboard...</p>}
      {error && <p>Error loading dashboard: {error.message}</p>}
      <div id="dashboardContainer"></div>
    </div>
  );
};

export default Dashboard1;
